import React from 'react';
import Confetti from 'react-confetti'
import seedrandom from 'seedrandom';
import RichText from '../fabrique/RichText';
import ShareButtons from '../fabrique/ShareButtons';
import { cellMarked, COLUMNS, getCellIndex, ROWS, useMarkers, useWindowSize } from '../helpers/bingoHelpers';

import words from '../helpers/words';
import Cell from './Cell';
import MarkedColumn from './MarkedColumn';
import MarkedDiagonal from './MarkedDiagonal';
import MarkedRow from './MarkedRow';

const Bingo = ({
  generateBoard,
  seed,
}) => {
  const {
    bingo,
    diagonal1Marked,
    diagonal2Marked,
    newLine,
    markedCells,
    markedRows,
    markedColumns,
    toggleCell,
  } = useMarkers();
  const { width, height } = useWindowSize();
  const [ready, setReady] = React.useState(false);
  React.useLayoutEffect(() => {
    window.setTimeout(() => setReady(true), 100);
  }, [])
  const rows = React.useMemo(() => {
    const rng = seedrandom.xor4096(parseInt(seed));
    const weights = words.map(() => rng());
    const sortedWords = words.slice().sort((a, b) => {
      const indexA = words.indexOf(a);
      const indexB = words.indexOf(b);

      return weights[indexA] - weights[indexB];
    });

    return Array.from(Array(ROWS)).map((_, row) => (
      Array.from(Array(COLUMNS)).map((_, column) => sortedWords[row * COLUMNS + column])
    ));
  }, [seed]);

  return (
    <div className="Bingo-wrapper">
      <div class="header">
        <div class="header__title">
          <h3 class="bingo-header">UITSLAGENAVOND BINGO</h3>
        </div>
      </div>
      <RichText>
        <button onClick={generateBoard}>
          <h5
            style={{
              background: 'white',
              padding: '4px 3px 0',
              margin: '6px',
              color: '#533bff',
            }}
          >START NIEUW SPEL</h5></button>
      </RichText>
      <div className="Bingo-table">
        {ready && rows.map((cells, row) => (
          cells.map((cell, column) => {
            const cellIndex = getCellIndex(row, column);

            return (
              <Cell
                cellIndex={cellIndex}
                word={cell}
                key={`row-${row}-column-${column}`}
                marked={cellMarked(cellIndex, markedCells)}
                toggleCell={toggleCell}
              />
            )
          })
        ))}
        {markedRows.map((row) => <MarkedRow key={`row-${row}`} row={row} />)}
        {markedColumns.map((column) => <MarkedColumn key={`column-${column}`} column={column} />)}
        {diagonal1Marked && <MarkedDiagonal diagonal={0} />}
        {diagonal2Marked && <MarkedDiagonal diagonal={1} />}
      </div>
      <RichText>
        <h3 className="share-h3">
          DEEL JOUW KAART
        </h3>
      </RichText>
      <div style={{
        background: 'white',
        float: 'right',
        margin: '0 5px 1em',
        display: 'inline-block',
        padding: '5px',
      }}>
        <ShareButtons label="GroenLinks Debat Bingo" href={window.location} />
      </div>
      <div style={{position: 'fixed', left: 0, top: 0}}>
        <Confetti
          width={width}
          height={height}
          numberOfPieces={bingo || newLine ? 200 : 0}
        />
      </div>
    </div>
  );
};

export default Bingo;
