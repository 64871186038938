import React from 'react';

const MarkedColumn = ({
  column,
}) => {
  const deg = React.useMemo(() => -5 + 10 * Math.random(), []);

  return (
    <div
      className="Marked MarkedColumn"
      style={{
        left: `calc(${column}.5 * var(--cell-width))`,
        transform: `rotate(${deg}deg)`,
      }}
    />
  );
}

export default MarkedColumn;
