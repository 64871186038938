import * as React from 'react';

const TIMEOUT = 1000;

const useFontsChecker = (font) => {
  const [fontLoaded, setFontLoaded] = React.useState(false);

  const loadFonts = React.useCallback(() => {
    Promise.race([
      document.fonts.load(font),
      new Promise((_, reject) => setTimeout(() => reject, TIMEOUT)),
    ]).then((fonts) => {
      if (fonts.length > 0) {
        setFontLoaded(true);
      } else {
        window.setTimeout(loadFonts, 1000);
      }
    }).catch(() => {
      console.error(new Error('Could not load fonts within timeout'));
    });
  }, [font]);

  React.useLayoutEffect(() => {
    loadFonts();
  }, [loadFonts]);

  return fontLoaded;
};

export default useFontsChecker;
