import React from 'react';

import logo from '../styles/images/logo.svg';

const MenuNavbarLogo = ({
  websiteName,
}) => (
  <div className="menu-bar__logo">
    <span className="logo">
      <span className="logo__span" hidden="screen">GroenLinks</span>
      <img className="logo logo__svg svg" src={logo} alt="logo" />
      <p className="menu-bar__department-title">{websiteName}</p>
    </span>
  </div>
);

export default MenuNavbarLogo;
