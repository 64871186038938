import React from 'react';

const PageWrapper = ({ children }) => (
  <div id="page-wrapper">
    <div className="container container--wrapper" id="page">
      {children}
    </div>
  </div>
);

export default PageWrapper;
