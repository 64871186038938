import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';

import HomePage from './HomePage';

const Routes = () => (
  <Switch>
    <Route path="/">
      <HomePage />
    </Route>
  </Switch>
);

export default Routes;
