import React from 'react';
import useFitText from 'use-fit-text';

const Textfit = ({ children, onReady }) => {
  const [memoizedSize, setMemoizedSize] = React.useState(null);

  const { fontSize, ref } = useFitText({
    onFinish: (size) => {
      if (!memoizedSize) {
        setMemoizedSize(size)
      }
      onReady();
    },
  });

  return (
    <div
      className="Fitter"
      ref={ref}
      style={{
        fontFamily: 'Helvetica Neue LT Std Webfont',
        fontSize: memoizedSize ? `${memoizedSize}%` : fontSize,
      }}
    >
      {children}
    </div>
  );
}

const Cell = ({
  cellIndex,
  toggleCell,
  marked,
  word,
}) => {
  const [ready, setReady] = React.useState(false);
  /* eslint-disable react-hooks/exhaustive-deps */
  const rotation = React.useMemo(() => Math.random() * 360, [marked]);
  /* eslint-enable react-hooks/exhaustive-deps */
  const onReady = React.useCallback(() => setReady(true), []);

  return (
    <div className={`Bingo-cell ${ready ? 'ready' : ''}`} onClick={() => toggleCell && toggleCell(cellIndex)}>
      <div style={{ transform: `rotate(${rotation}deg)`}} className={`Bingo-cell-mark ${marked ? 'marked' : ''}`}/>
      <div className="Bingo-label">
        <Textfit key={word} onReady={onReady}>
          {word}
        </Textfit>
      </div>
    </div>
  );
};

export default Cell;
