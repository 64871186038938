import React from 'react';

const MenuNavbarItems = ({
  children,
  side,
}) => (
  <div className={`menu-bar__items-${side}`}>
    {children}
  </div>
);

export default MenuNavbarItems;
