import React from 'react';
import { useHistory, useLocation } from 'react-router';

export const COLUMNS = 4;
export const ROWS = 4;

export const getCellIndex = (row, column) => (
  COLUMNS * row + column
);

export const cellMarked = (cellIndex, markedCells) => (
  markedCells.includes(cellIndex)
);

const calculateMarkedLines = (markedCells, isRow) => {
  const marked = [];
  for (let column = 0; column < COLUMNS; column += 1) {
    let isMarked = true;
    for (let row = 0; row < ROWS; row += 1) {
      const cellIndex = isRow ? getCellIndex(column, row) : getCellIndex(row, column);
      if (!cellMarked(cellIndex, markedCells)) {
        isMarked = false;
      }
    }
    if (isMarked) {
      marked.push(column);
    }
  }
  return marked;
}

const checkLine = (markedCells, cells) => (
  cells.every((cellIndex) => cellMarked(cellIndex, markedCells))
);

const calculateDiagonal = (markedCells, direction) => (
  checkLine(markedCells, [
    getCellIndex(direction ? 0 : 3, 0),
    getCellIndex(direction ? 1 : 2, 1),
    getCellIndex(direction ? 2 : 1, 2),
    getCellIndex(direction ? 3 : 0, 3)
  ])
)

export const useMarkers = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const markedParam = searchParams.get('marked');
  const [markedCells, setMarkedCells] = React.useState(markedParam?.split(',')?.map((i) => parseInt(i)) || []);
  const markedRows = React.useMemo(() => (
    calculateMarkedLines(markedCells, true)
  ), [markedCells]);
  const markedColumns = React.useMemo(() => (
    calculateMarkedLines(markedCells, false)
  ), [markedCells]);
  const diagonal1Marked = React.useMemo(() => (
    calculateDiagonal(markedCells, true)
  ), [markedCells]);
  const diagonal2Marked = React.useMemo(() => (
    calculateDiagonal(markedCells, false)
  ), [markedCells]);
  const [newLine, setNewLine] = React.useState(false);

  /* eslint-disable react-hooks/exhaustive-deps */
  const toggleCell = React.useCallback((cellIndex) => {
    setMarkedCells((currentMarked) => {
      const newMarked = currentMarked.slice();
      const index = newMarked.indexOf(cellIndex);
      if (index === -1) {
        newMarked.push(cellIndex);
      } else {
        newMarked.splice(index, 1);
      }
      searchParams.set('marked', newMarked.join(','));
      location.search = searchParams.toString();
      history.replace(location);

      const newLineMarked = markedRows.length < calculateMarkedLines(newMarked, true).length
        || markedColumns.length < calculateMarkedLines(newMarked, false).length
        || (!diagonal1Marked && calculateDiagonal(newMarked, true))
        || (!diagonal2Marked && calculateDiagonal(newMarked, false))
      if (newLineMarked) {
        setNewLine(true);
        window.setTimeout(() => {
          setNewLine(false);
        }, 1000)
      }

      return newMarked;
    });
  }, [
    markedRows,
    markedColumns,
    diagonal1Marked,
    diagonal2Marked,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const bingo = React.useMemo(() => (
    markedCells.length === ROWS * COLUMNS
  ), [markedCells]);

  return {
    bingo,
    newLine,
    diagonal1Marked,
    diagonal2Marked,
    markedCells,
    markedRows,
    markedColumns,
    toggleCell,
  };
};

export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
