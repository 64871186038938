import React from 'react';

const MarkedRow = ({
  row,
}) => {
  const deg = React.useMemo(() => -5 + 10 * Math.random(), []);

  return (
    <div
      className="Marked MarkedRow"
      style={{
        top: `calc(${row}.5 * var(--cell-width))`,
        transform: `rotate(${deg}deg)`,
      }}
    />
  );
};

export default MarkedRow;
