import React from 'react';
import { useHistory, useLocation } from 'react-router';

import Bingo from '../components/Bingo';
import WordTester from '../components/WordTester';
import Main from '../fabrique/Main';
import useFontsChecker from '../helpers/useFontLoader';

const HomePage = () => {
  const helveticaLoaded = useFontsChecker('normal 16px Helvetica Neue LT Std Webfont');
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const seed = searchParams.get('seed');
  /* eslint-disable react-hooks/exhaustive-deps */
  const generateBoard = React.useCallback(() => {
    const newSeed = Math.random().toString().split('.')[1];
    history.push(`?seed=${newSeed}`)
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (!seed) {
      generateBoard();
    }
  }, [seed, generateBoard]);

  if (!helveticaLoaded) {
    return null;
  }

  return (
    <React.Fragment>
      <Main>
        {seed === 'test' && <WordTester />}
        {seed && seed !== 'test'  && <Bingo key={seed} seed={seed} generateBoard={generateBoard} /> }
      </Main>
    </React.Fragment>
  );
}

export default HomePage;
