import React from 'react';

import MenuNavbarLogo from './MenuNavbarLogo';
import MenuNavbarWrapper from './MenuNavbarWrapper';

const Menu = ({
  menuNavbarLeft: MenuNavbarLeft,
  menuNavbarRight: MenuNavbarRight,
  menuOverlay: MenuOverlay,
  websiteName,
}) => {
  const [overlay, setOverlayRaw] = React.useState(false);
  const setOverlay = React.useCallback((newOverlay) => {
    if (newOverlay) {
      document.body.classList.add('overlay-visible');
      document.body.classList.add('prevent-scrolling');
    } else {
      document.body.classList.remove('overlay-visible');
      document.body.classList.remove('prevent-scrolling');
    }
    setOverlayRaw(newOverlay);
  }, []);
  const [scrolled, setScrolled] = React.useState(!websiteName);
  const handleScroll = React.useCallback(() => {
    if (websiteName) {
      setScrolled(window.pageYOffset > 20);
    }
  }, [websiteName]);
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  })

  return (
    <React.Fragment>
      <MenuNavbarWrapper overlay={overlay} scrolled={scrolled}>
        <MenuNavbarLeft setOverlay={setOverlay} />
        <MenuNavbarLogo websiteName={websiteName} />
        <MenuNavbarRight />
      </MenuNavbarWrapper>
      <MenuOverlay />
    </React.Fragment>
  );}

export default Menu;
