import React from 'react';
import Content from '../fabrique/Content';
import words from '../helpers/words';
import Cell from './Cell';

const WordTester = () => {
  const [word, setWord] = React.useState('');

  return (
    <div>
      <Content>
        <div><label>Voer een test woord in:</label></div>
        <input
          value={word}
          onChange={(e) => {
            e.preventDefault();
            setWord(e.target.value);
          }}
        />
      </Content>
      <div className="Bingo-wrapper">
        <div className="Bingo-table">
          <Cell word={word} />
          {words.map((word) => <Cell key={word} word={word} />)}
        </div>
      </div>
    </div>
  );
}

export default WordTester;
