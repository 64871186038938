import React from 'react';

const MarkedDiagonal = ({
  diagonal,
}) => {
  const deg = React.useMemo(() => -5 + 10 * Math.random(), []);

  return (
    <div
      className="Marked MarkedDiagonal"
      style={{
        top: '50%',
        transform: `rotate(${45 + deg - 90 * diagonal}deg)`,
      }}
    />
  );
}

export default MarkedDiagonal;
