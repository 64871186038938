import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import PageWrapper from '../fabrique/PageWrapper';
import Routes from '../routes';

import AppMenu from './AppMenu';

const App = () => (
  <Router>
    <div className="background" />
    <PageWrapper>
      <AppMenu />
      <Routes />
    </PageWrapper>
  </Router>
);

export default App;
