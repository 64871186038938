import React from 'react';
import ShareButton from './ShareButton';

const ShareButtons = ({
  label,
  href,
}) => (
  <React.Fragment>
    <ShareButton
      data-open-popup=""
      href={`https://facebook.com/sharer/sharer.php?u=${href}`}
      type="facebook"
    >
      <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
        <path fillRule="nonzero" d="M11.33 2.99h1.64V.13A20.9 20.9 0 0010.59 0C8.24 0 6.63 1.49 6.63 4.23v2.52h-2.6v3.2h2.6V18H9.8V9.95h2.49l.4-3.2H9.8v-2.2c0-.93.26-1.56 1.53-1.56z" />
      </svg>
    </ShareButton>
    <ShareButton
      data-open-popup=""
      href={`https://twitter.com/intent/tweet/?url=${href}&status=${label.replace(/ /g,"%20")}`}
      type="twitter"
    >
      <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
        <path d="M18 3.78c-.67.3-1.38.5-2.13.6a3.75 3.75 0 001.63-2.1c-.72.44-1.5.75-2.34.92a3.66 3.66 0 00-2.7-1.2 3.74 3.74 0 00-3.69 3.79c0 .3.03.58.09.86a10.36 10.36 0 01-7.6-3.96 3.82 3.82 0 001.13 5.06 3.57 3.57 0 01-1.67-.47v.04a3.78 3.78 0 002.96 3.72A3.6 3.6 0 012 11.1a3.74 3.74 0 003.45 2.64A7.3 7.3 0 010 15.3 10.2 10.2 0 005.66 17c6.8 0 10.5-5.77 10.5-10.77v-.49A7.47 7.47 0 0018 3.78z" />
      </svg>
    </ShareButton>
    <ShareButton
      data-action="share/whatsapp/share"
      data-open-popup=""
      href={`https://api.whatsapp.com/send?text=${label.replace(/ /g,"%20")}%20${href}`}
      type="whatsapp"
    >
      <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
        <path d="M.08 8.92c0-7.78 9.34-11.82 15.12-6.5l.19.17-.01.03A8.85 8.85 0 0118 8.92a8.98 8.98 0 01-13.02 7.96l-.23-.12L0 18l1.27-4.62a8.88 8.88 0 01-1.2-4.46zm8.97-6.95a7.06 7.06 0 00-6.13 10.59l.12.2.17.27-.71 2.6 2.67-.7.26.16a7.1 7.1 0 0010.7-6.06c0-1.89-.74-3.66-2.08-4.98a7.03 7.03 0 00-5-2.08zM6.22 5.81c.23-.24.49-.25.84-.22h.08c.09 0 .19.03.28.21l.05.1L8 7.16l.05.1c.04.1.07.2.01.33-.34.65-.71.7-.54 1l.15.23c.18.27.48.69.87 1.04.7.62 1.3.82 1.47.9.19.1.3.08.4-.04l.23-.27.28-.35.07-.09c.13-.2.24-.2.89.12l.77.38v-.04l.2.08c.07.04.13.08.16.13.04.08.04.44-.11.87-.16.43-.89.82-1.24.87-.32.05-.71.07-1.15-.07l-.4-.14c-1.37-.5-2.25-1-3.77-3-.85-1.19-1.1-2.3-.1-3.4z" />
      </svg>
    </ShareButton>
  </React.Fragment>
);

export default ShareButtons;
