import React from 'react';
import { Link } from "react-router-dom";

const Button = ({
  disabled,
  href,
  label,
  onClick,
  type,
}) => {
  const className = [
    'button',
    `button--${type}`,
    disabled ? 'button--disabled' : ''
  ].join(' ');
  const external = href?.includes('://');
  const Component = external ? 'a' : Link;
  const props = external ? {
    href,
    target: '_blank',
    rel: 'noopener noreferrer'
  } : {
    to: href,
  };

  return (
    <Component className={className} aria-label={label} onClick={onClick} {...props}>
      <span className="button__span">{label}</span>
    </Component>
  );
}

Button.defaultProps = {
  type: 'secondary'
}

export default Button;
