import React from 'react';

const MenuNavbarWrapper = ({
  children,
  overlay,
  scrolled,
}) => (
  <div
    className={`menu-bar menu-bar--local${scrolled ? ' menu-bar--document-scrolled' : ''}${overlay ? ' menu-bar--overlay-open' : ''}`}
    aria-label="Navigatie"
  >
    <div className="menu-bar__bar">
      {children}
    </div>
  </div>
);

export default MenuNavbarWrapper;
