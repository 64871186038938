import React from 'react';

const RichText = ({
  children,
  type,
}) => (
  <div className={`richtext richtext--${type}`}>
    {children}
  </div>
);

RichText.defaultProps = {
  type: 'regular',
}

export default RichText;
