import React from 'react';

const ShareButton = ({
  children,
  type,
  ...props
}) => (
  <a
    {...props}
    className={`share-button share-button--${type}`}
    target="_blank"
    rel="noopener noreferrer"
    aria-label="Deel op social media"
  >
    <span className="share-button__icon">
      {children}
    </span>
    <span
      className="share-button__span"
      hidden="screen"
    >
      Deel op social media
    </span>
  </a>
);

export default ShareButton;
