import Button from '../fabrique/Button';
import Menu from '../fabrique/Menu';
import MenuNavbarItems from '../fabrique/MenuNavbarItems';
import MenuOverlayItems from '../fabrique/MenuOverlayItems';

const MenuOverlay = () => (
  <MenuOverlayItems>
    {/*<MenuOverlaySection type="large">*/}
    {/*  <MenuOverlayItem href="/" label="Home" />*/}
    {/*  <MenuOverlayItem href="/standpunten" label="Standpunten" />*/}
    {/*  <MenuOverlayItem href="/kom-in-actie" label="Kom in actie" />*/}
    {/*</MenuOverlaySection>*/}
    {/*<MenuOverlaySection type="regular">*/}
    {/*  <MenuOverlayItem href="https://groenlinks.nl/onze-mensen" label="Onze mensen" />*/}
    {/*  <MenuOverlayItem href="https://groenlinks.nl/afdeling" label="Onze afdeling" />*/}
    {/*  <MenuOverlayItem href="https://groenlinks.nl/nieuws" label="Nieuws" />*/}
    {/*  <MenuOverlayItem href="https://groenlinks.nl/agenda" label="Agenda" />*/}
    {/*  <MenuOverlayItem href="https://groenlinks.nl" label="Naar GroenLinks.nl" />*/}
    {/*</MenuOverlaySection>*/}
    {/*<MenuOverlaySection type="footer">*/}
    {/*  <MenuOverlayItem href="https://mijn.groenlinks.nl" label="Mijn GroenLinks" />*/}
    {/*</MenuOverlaySection>*/}
  </MenuOverlayItems>
);

const MenuNavbarLeft = ({ setOverlay }) => (
  <MenuNavbarItems side="left" >
    {/*<MenuNavbarToggle setOverlay={setOverlay} />*/}
    {/*<MenuNavbarItem href="/standpunten" label="Standpunten" />*/}
    {/*<MenuNavbarItem href="/kom-in-actie" label="Kom in actie" />*/}
  </MenuNavbarItems>
)

const MenuNavbarRight = () => (
  <MenuNavbarItems side="right" >
    {/*<MenuNavbarItem href="https://groenlinks.nl/word-lid" label="Word lid" />*/}
    <Button href="https://groenlinks.nl/kom-in-actie" label="Doe mee(r)" />
  </MenuNavbarItems>
)

const AppMenu = () => (
  <Menu
    menuOverlay={MenuOverlay}
    menuNavbarLeft={MenuNavbarLeft}
    menuNavbarRight={MenuNavbarRight}
  />
);

export default AppMenu;
