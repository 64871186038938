import React from 'react';

const Content = ({
  children,
}) => (
  <div className="content">
    <div className="content__grid">
      <div className="content__wrapper">
        {children}
      </div>
    </div>
  </div>
);

export default Content;
