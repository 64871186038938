const words = [
  "Schiermonnikoog is binnen",
  "‘Het Wopke effect’",
  "‘SGP en de biblebelt’",
  "‘Andere verkiezingen’",
  "‘Rutte IV’",
  "‘Normaal zouden we nu...’",
  "‘Briefstemmen’",
  "‘70-plussers’",
  "‘Jongeren bemanden de stemlokalen’",
  "‘Niet alleen senioren stemden vóór 17 maart’",
  "‘Heel veel partijen’",
  "‘Volt in de TK’",
  "‘Bij1 in de TK’",
  "‘JA21 in de TK’",
  "Geen Henk Krol meer",
  "‘Er wordt druk geteld’",
  "Baudet doet gek",
  "‘Pan-Europese partij’",
  "‘Versnippering’",
  "‘Corona’",
  "‘Tien jaar Rutte’",
  "‘Online campagne’",
  "‘Meer vrouwelijke lijsttrekkers’",
  "Presentator rekt tijd",
  "‘Formatie’",
  "‘FvD uitgesloten’",
  "‘PVV uitgesloten’",
  "Technische fout NOS",
  "‘Coalitie met 4/5 partijen’",
  "Herhaling van uitslag",
  "Links in de Randstad",
  "Speech lijsttrekker",
  "‘De wereld na Corona’",
  "‘Spannende avond’",
  "Mondkapje in beeld",
  "‘Corona verkiezingen’",
  "Campagneleider aan het woord",
  "Lijsttrekker aan het woord",
  "Gespannen mensen",
  "Presentator maakt random coalities",
];

export default words;
